import {Theme} from '@material-ui/core';

const styles = (theme: Theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(2, 0, 1, 0),
            },
            "& > .colorPicker": {
                height: "30px",
            }
        },
        colorPicker: {
            width: "224px",
            height: "40px",
        }
    }
}

export default styles;
