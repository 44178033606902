import {Theme} from '@material-ui/core';

const styles = (theme: Theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(1, 0, 1, 0),
            },
            "& > .filedrop": {
                borderColor: "#696969",
                borderWidth: "2px",
                borderStyle: "dashed",
                color: "#696969",
                width: "400px",
                padding: "20px"
            }
        },
    }
}

export default styles;
