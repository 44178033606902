import {isValidDate, toStandardFormatDate} from "./date";

const BASE_DATE_TICKS = 621355968000000000;
const TICKS_PER_MILLISECOND = 10000;

export function ticksToDatetime(value: string): string {
    const ticks = (parseInt(value) - BASE_DATE_TICKS) / TICKS_PER_MILLISECOND;
    let date = new Date(ticks);
    date.setMinutes(date.getMinutes() + new Date().getTimezoneOffset());
    if (isValidDate(date)) {
        return toStandardFormatDate(date);
    }
    return '';
}

export function datetimeToTicks(value: string): number {
    const date = new Date(value);
    // Date.getTime() is UTC time.
    return ((date.getTime() * TICKS_PER_MILLISECOND) + BASE_DATE_TICKS);
}
