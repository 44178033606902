import {Button} from "@material-ui/core";
import React, {forwardRef} from "react";

interface OutlinedButtonProps {
    label: string,
    autoFocus?: boolean,
    readOnly?: boolean,
    helperText?: string,
    fullWidth?: boolean,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onClick?: (e?: React.ChangeEvent<any>) => void,
}

const OutlinedButton = forwardRef<HTMLInputElement, OutlinedButtonProps>((props, ref) => {
    const {label, onClick} = props;
    return (
        <Button variant="outlined" color="primary" onClick={onClick}>
            {label}
        </Button>
    )
});

export default OutlinedButton;


