import React, {useEffect, useState} from "react";
import {Box, Button, withStyles, WithStyles} from "@material-ui/core";
import {loadAsTextFromFiles, saveToText} from "../../common/io/BlobIO";
import { OutlinedButton } from "../common/custom-components";
import "./LineConvertFunction";
import styles from "./styles";
import {analyze, convert, LineStatus, LineType} from "./LineConvertFunction";
import { FileDrop } from 'react-file-drop';

interface LineConvertActionProps extends WithStyles<typeof styles> {
}

function LineConvertAction(props: LineConvertActionProps) {
    let [originText, setOriginText] = useState<string>();
    let [linesInfo, setLinesInfo] = useState<LineStatus>();
    const [fileName, setFileName] = useState<string>();

    useEffect(() => {
        analyze(originText).then(status => setLinesInfo(status));
    }, [originText])

    let onFile = (files: FileList | null) => {
        if (files) {
            loadAsTextFromFiles([files[0]], (file, content) => {
                setFileName(file.name);
                setOriginText(content)
            })
        }
    }

    let onSave = (type: LineType) => {
        convert(originText, type).then(converted => {
            saveToText(converted, `converted-to-${LineType[type].toLowerCase()}.txt`)
        })
    }

    const { classes } = props;
    const dropLabel = fileName ? fileName : '👏 여기에 파일을 끌어다 놓으세요 ';

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center">
                <Box>
                    <div className={classes.dropbox}>
                        <FileDrop onDrop={(files, event) => onFile(files)}>
                            {dropLabel}
                        </FileDrop>
                    </div>
                    <br/>
                    <input
                        accept="*"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={e => onFile(e.target.files)}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">Choose File</Button>
                    </label>
                </Box>
            </Box>
            <div/>
            <label>Length: </label><label className={"value"}>{originText && originText.length}</label>
            <div/>
            <label>CR-LF Count: </label><label className={"value"}>{linesInfo && linesInfo.crlf}</label>
            <div/>
            <label>LF Count: </label><label className={"value"}>{linesInfo && linesInfo.lf}</label>
            <div/>
            <label>CR Count: </label><label className={"value"}>{linesInfo && linesInfo.cr}</label>
            <div/>
            <OutlinedButton label="CR-LF 로 변환" onClick={() => onSave(LineType.CRLF)}/>
            <OutlinedButton label="LF 로 변환" onClick={() => onSave(LineType.LF)}/>
            <OutlinedButton label="CR 로 변환" onClick={() => onSave(LineType.CR)}/>
        </div>
    )
}

export default withStyles(styles)(LineConvertAction);
