import {FormControl, FormHelperText, InputLabel, OutlinedInput} from "@material-ui/core";
import React, {forwardRef} from "react";

interface FormControlInputProps {
    label: string,
    value: string,
    ref?: any,
    multiline?: boolean,
    rows?: number,
    // rowsMax?: number,
    autoFocus?: boolean,
    readOnly?: boolean,
    helperText?: string,
    fullWidth?: boolean,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const FormControlInput = forwardRef<HTMLInputElement, FormControlInputProps>((props, ref) => {
    const {label, value, multiline, rows, autoFocus, readOnly, helperText, fullWidth, onChange, onFocus} = props;
    return (
        <FormControl variant="outlined" fullWidth={fullWidth}>
            <InputLabel htmlFor="component-simple">{label}</InputLabel>
            <OutlinedInput label={label} value={value} multiline={multiline} rows={rows || 8}
                           inputRef={ref}
                           margin="dense"
                           rowsMax={rows || 8}
                           autoFocus={autoFocus}
                           readOnly={readOnly}
                           onFocus={onFocus}
                           onChange={onChange}/>
            {helperText && (
                <FormHelperText id="component-error-text">{helperText}</FormHelperText>
            )}
        </FormControl>
    )
});

export default FormControlInput;


