import React from "react";
import TextField from "@material-ui/core/TextField";

interface DateTimePickerProps {
    label: string,
    value: string,
    autoFocus?: boolean,
    helperText?: string,
    fullWidth?: boolean,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function DateTimePicker(props: DateTimePickerProps) {
    const {label, value, autoFocus, onChange, onFocus} = props;
    return (
        <TextField
            id="datetime-local"
            label={label}
            type="datetime-local"
            value={value}
            InputLabelProps={{
                shrink: true,
            }}
            autoFocus={autoFocus}
            onChange={onChange}
            onFocus={onFocus}
        />
    )
}


