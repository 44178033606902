export function RGBToHex(r: any, g: any, b: any) {
    const _r = parseInt(r);
    const _g = parseInt(g);
    const _b = parseInt(b);
    return "#" + ((1 << 24) + (_r << 16) + (_g << 8) + _b).toString(16).slice(1);
}

export function hexToRGB(hex: string) {
    let r = 0, g = 0, b = 0;

    // 3 digits
    if (hex.length === 4) {
        r = parseInt("0x" + hex[1] + hex[1]);
        g = parseInt("0x" + hex[2] + hex[2]);
        b = parseInt("0x" + hex[3] + hex[3]);

        // 6 digits
    } else if (hex.length === 7) {
        r = parseInt("0x" + hex[1] + hex[2]);
        g = parseInt("0x" + hex[3] + hex[4]);
        b = parseInt("0x" + hex[5] + hex[6]);
    }
    return {
        r: r, g: g, b: b
    }
}

function padding2DigitHex(v: number) {
    return ("00" + v.toString(16).toUpperCase()).slice(-2)
}

export function getComplementaryColor(origin: string) {
    let rgb = hexToRGB(origin);
    rgb.r = 255 - rgb.r;
    rgb.g = 255 - rgb.g;
    rgb.b = 255 - rgb.b;
    return `#${padding2DigitHex(rgb.r)}${padding2DigitHex(rgb.g)}${padding2DigitHex(rgb.b)}`;
}

export function RGBToHSL(r: any, g: any, b: any) {
    // Make r, g, and b fractions of 1
    r /= 255;
    g /= 255;
    b /= 255;

    // Find greatest and smallest channel values
    let cmin = Math.min(r,g,b);
    let cmax = Math.max(r,g,b);
    const delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;

    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
}

export function RGBtoCMYK(r: any, g: any, b: any, normalized?: any) {
    var c = 1 - (r / 255);
    var m = 1 - (g / 255);
    var y = 1 - (b / 255);
    var k = Math.min(c, Math.min(m, y));

    c = (c - k) / (1 - k);
    m = (m - k) / (1 - k);
    y = (y - k) / (1 - k);

    if(!normalized){
        c = Math.round(c * 10000) / 100;
        m = Math.round(m * 10000) / 100;
        y = Math.round(y * 10000) / 100;
        k = Math.round(k * 10000) / 100;
    }

    c = isNaN(c) ? 0 : c;
    m = isNaN(m) ? 0 : m;
    y = isNaN(y) ? 0 : y;
    k = isNaN(k) ? 0 : k;

    return { c, m, y, k }
}
