import React, {useCallback, useState} from 'react';
import {Box, withStyles, WithStyles} from "@material-ui/core";
import {FormControlInput, DateTimePicker} from "../common/custom-components";
import {datetimeToTimestamp, timestampToDatetime, IResult} from "./TimestampToDateFunction";
import styles from "./styles";

interface TimestampToDateActionProps extends WithStyles<typeof styles> {
}

function TimestampToDateAction(props: TimestampToDateActionProps) {
    const [timestamp, setTimestamp] = useState<IResult>({
        value: '',
        utc: '',
        local: '',
        type: '',
    })
    const [datetime, setDatetime] = useState<IResult>({
        value: '',
        utc: '',
        local: '',
    })

    const handleTimestampChange = useCallback(e => {
        setTimestamp(timestampToDatetime(e.target.value));
    }, []);

    const handleDateTimeChange = useCallback(e => {
        setDatetime(datetimeToTimestamp(e.target.value));
    }, []);

    const {classes} = props;

    return (
        <form className={classes.root}>
            <Box
                display="flex"
                alignItems="center"
                // p={1}
                // m={1}
                // bgcolor="background.paper"
                // css={{ height: 100 }}
            >
                <Box>
                    <FormControlInput label="UTC timestamp" value={timestamp.value} autoFocus
                                      onChange={handleTimestampChange}/>
                </Box>
                <Box p={1}>
                    {`(${timestamp.type || 'not determined'})`}
                </Box>
            </Box>
            <div>
                <div>
                    <span>UTC: </span>
                    <span>{timestamp.utc}</span>
                </div>
                <div>
                    <span>Local: </span>
                    <span>{timestamp.local}</span>
                </div>
            </div>
            <div>
                <Box display="flex" alignItems="center">
                    <Box>
                        <DateTimePicker label="DateTime" value={datetime.value} onChange={handleDateTimeChange}/>
                    </Box>
                    <Box p={1}>(local)</Box>
                </Box>
                <div>
                    <span>UTC: </span>
                    <span>{datetime.utc}</span>
                </div>
                <div>
                    <span>Local:  </span>
                    <span>{datetime.local}</span>
                </div>
            </div>
        </form>
    );
}

export default withStyles(styles)(TimestampToDateAction);
