import { createAction, ActionType, createReducer } from 'typesafe-actions';
import {Language} from "../common/Types";

const SET_MENU_NAME = 'common/SET_MENU_NAME';
const SET_LANGUAGE = 'common/SET_LANGUAGE';

export const setMenuName = createAction(SET_MENU_NAME, (name: string) => name)();
export const setLanguage = createAction(SET_LANGUAGE, (code: Language) => code)();

const actions = { setMenuName, setLanguage };
type CommonAction = ActionType<typeof actions>;

interface CommonState {
    menuName: string,
    language: Language,
}

const initialState: CommonState = {
    menuName: '',
    language: Language.KO,
}

const common = createReducer<CommonState, CommonAction>(initialState, {
    [SET_MENU_NAME]: ((state, action) => ({ ...state, menuName: action.payload })),
    [SET_LANGUAGE]: ((state, action) => ({ ...state, language: action.payload}))
})

export default common;
