import React, {forwardRef} from 'react'
import ListItem from '@material-ui/core/ListItem'
import {NavLink, NavLinkProps} from 'react-router-dom'

export interface MenuItemComponentProps {
    className?: string
    link?: string | null // because the InferProps props allows alows null value
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const MenuItemComponent: React.FC<MenuItemComponentProps> = props => {
    const {className, onClick, link, children} = props

    // If this menu has sub menu items.
    if (!link) {
        return (
            <ListItem
                button
                className={className}
                children={children}
                onClick={onClick}
            />
        )
    }

    // Return a LitItem with a link component
    return (
        <ListItem
            button
            className={className}
            children={children}
            onClick={onClick}
            component={
                forwardRef((props: NavLinkProps, ref: any) =>
                    <NavLink exact {...props} innerRef={ref}/>
                )
            }
            to={link}
        />
    )
}

export default MenuItemComponent
