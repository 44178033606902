import React, {useEffect, useState} from "react";
import {Checkbox, FormControl, FormControlLabel, withStyles, WithStyles} from "@material-ui/core";
import {Base64} from "js-base64";
import FormControlInput from "../common/custom-components/FormControlInput";
import styles from "./styles";

enum DatumType {
    ORIGIN,
    BASE64,
    URL_ENCODE
}

interface Base64ActionProps extends WithStyles<typeof styles> {
}

function Base64Action(props: Base64ActionProps) {
    let [urlSafe, setUrlSafe] = useState(false);

    let [originText, setOriginText] = useState("");
    let [base64Text, setBase64Text] = useState("");
    let [urlencText, setUrlEncText] = useState("");

    let [datum, setDatum] = useState(DatumType.ORIGIN)

    let [resultInvalid, setResultInvalid] = useState(false);
    let [, setUrlEncInvalid] = useState(false);

    const {classes} = props;

    useEffect(() => {
        switch (datum) {
            case DatumType.ORIGIN:
                setBase64Text(Base64.encode(originText, urlSafe));          // text -> base64
                setUrlEncText(encodeURIComponent(originText));              // text -> urlenc
                setResultInvalid(false);
                setUrlEncInvalid(false);
                break;
            case DatumType.BASE64:
                try {
                    if (!Base64.isValid(base64Text) ||
                        (urlSafe && ["+", "/", "="].some(esc => base64Text.includes(esc))) ||
                        (!urlSafe && ["-", "_"].some(esc => base64Text.includes(esc)))
                    ) {
                        setResultInvalid(true)
                        setOriginText("")
                        setUrlEncText("")
                        break;
                    }
                    setOriginText(Base64.decode(base64Text))                // base64 -> text
                } catch (err) {
                    setResultInvalid(true)
                    setOriginText("")
                    setUrlEncText("")
                    break;
                }
                setUrlEncText(encodeURIComponent(originText));              // text -> urlenc
                setResultInvalid(false)
                setUrlEncInvalid(false);
                break;
            case DatumType.URL_ENCODE:
                try {
                    setOriginText(decodeURIComponent(urlencText));          // urlenc -> text
                } catch (err) {
                    setUrlEncInvalid(true)
                    setOriginText("")
                    setBase64Text("")
                    break;
                }
                setBase64Text(Base64.encode(originText, urlSafe));          // text -> base64
                setResultInvalid(false);
                setUrlEncInvalid(false);
                break;
        }
    }, [originText, base64Text, urlencText, datum, resultInvalid, urlSafe])

    // let promptClass = (type: string | null = null) => {
    //     if (type === "urlenc") {
    //         return cn("prompt", urlencInvalid ? "prompt-invalid" : "prompt-valid")
    //     }
    //     return cn("prompt", resultInvalid ? "prompt-invalid" : "prompt-valid");
    // };

    return (
        <form className={classes.root}>
            <FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={urlSafe}
                            onChange={e => setUrlSafe(e.target.checked)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="URL Safe"
                />
            </FormControl>
            <FormControlInput label="원문 텍스트" value={originText} multiline fullWidth autoFocus
                              onFocus={() => setDatum(DatumType.ORIGIN)}
                              onChange={(e) => {
                                  setOriginText(e.target.value)
                                  setDatum(DatumType.ORIGIN)
                              }}/>
            <FormControlInput label="BASE 64 encoded" value={base64Text} multiline fullWidth
                              onFocus={() => setDatum(DatumType.BASE64)}
                              onChange={(e) => {
                                  setBase64Text(e.target.value)
                                  setDatum(DatumType.BASE64)
                              }}/>
            <FormControlInput label="URL encoded" value={urlencText} multiline fullWidth
                              onFocus={() => setDatum(DatumType.URL_ENCODE)}
                              onChange={(e) => {
                                  setUrlEncText(e.target.value)
                                  setDatum(DatumType.URL_ENCODE)
                              }}/>
        </form>
    )
}

export default withStyles(styles)(Base64Action);
