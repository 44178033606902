import React, {useEffect, useState} from "react";
import {withStyles, WithStyles} from "@material-ui/core";
import FormControlInput from "../common/custom-components/FormControlInput";
import {convertPronunciationForRoman, convertToRoman} from "../../common/language/ko/KoreanRomanNotation";
import styles from "./styles";

interface RomanActionProps extends WithStyles<typeof styles> {
}

function RomanAction(props: RomanActionProps) {
    let [hangulText, setHangulText] = useState("");
    let [pronunciationText, setPronunciationText] = useState("");
    let [pronunciationForRomanText, setPronunciationForRomanText] = useState("");
    let [romanText, setRomanText] = useState("");
    const {classes} = props;

    useEffect(() => {
        let pronounced = convertPronunciationForRoman(hangulText);
        setPronunciationText(pronounced.pronunciation);
        setPronunciationForRomanText(pronounced.pronunciationForRoman)
        let roman = convertToRoman(pronunciationForRomanText);
        setRomanText(roman.roman);

    }, [hangulText, pronunciationText, pronunciationForRomanText, romanText]);

    return (
        <form className={classes.root}>
            <FormControlInput label="입력 텍스트" value={hangulText} multiline fullWidth autoFocus rows={6}
                              onChange={(e) => setHangulText(e.target.value)}/>
            <FormControlInput label="표준 발음법" value={pronunciationText} multiline fullWidth rows={6} readOnly/>
            <FormControlInput label="로마자 표기를 위한 발음법" value={pronunciationForRomanText} multiline fullWidth rows={6} readOnly/>
            <FormControlInput label="로마자 표기" value={romanText} multiline fullWidth rows={6} readOnly/>
        </form>
    )
}

export default withStyles(styles)(RomanAction);
