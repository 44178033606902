import React, {DragEvent as ReactDragEvent, useEffect, useState} from "react";
import {Checkbox, FormControlLabel, withStyles, WithStyles} from "@material-ui/core";
import {Base64} from "js-base64";
import {loadAsBinaryFromFiles} from "../../common/io/BlobIO";
import {FileDrop} from 'react-file-drop';
import styles from "./styles";
import FormControlInput from "../common/custom-components/FormControlInput";


interface Base64FromFileActionProps extends WithStyles<typeof styles> {
}

function Base64FromFileAction(props: Base64FromFileActionProps) {
    let [urlSafe, setUrlSafe] = useState(false);
    let [targetName, setTargetName] = useState<string>("🤗 이곳에 파일을 끌어다가 놓으세요");
    let [urlencText, setUrlEncText] = useState("");
    let [targetFile, setTargetFile] = useState<File>();

    let onDrop = (files: FileList | null, event: ReactDragEvent<HTMLDivElement>) => {
        if (files != null) {
            setTargetName(files[0].name);
            setTargetFile(files[0]);
        }
    }

    useEffect(() => {
        if(targetFile) {
            loadAsBinaryFromFiles([targetFile], (file: File, content: ArrayBuffer) => {
                let data = new Uint8Array(content);
                let base64 = Base64.fromUint8Array(data, urlSafe);
                setUrlEncText(base64);
            });
        }
    }, [urlSafe, targetFile])

    return (
        <div className={props.classes.root}>
            <div className={"filedrop"}>
                <FileDrop onDrop={onDrop}>
                    {targetName}
                </FileDrop>
            </div>
            <br/>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={urlSafe}
                        onChange={e => setUrlSafe(e.target.checked)}
                        color="primary"
                    />
                }
                label="URL Safe"
            />
            <br/>
            <FormControlInput label="URL encoded" value={urlencText} multiline fullWidth readOnly={true}/>
        </div>
    )
}

export default withStyles(styles)(Base64FromFileAction);