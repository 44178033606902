import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import Typography from "@material-ui/core/Typography";

import RomanAction from '../actions/roman/RomanAction'
import LevenshteinAction from "../actions/lev/LevenshteinAction";
import LineConvertAction from "../actions/line-convert/LineConvertAction";
import TicksToDateAction from "../actions/ticks-to-date/TicksToDateAction";
import NoteAction from "../actions/note/NoteAction";
import ColorCodeAction from "../actions/color/ColorCodeAction";
import TimezoneAction from "../actions/timezone/TimezoneAction";
import TimestampToDateAction from "../actions/timestamp-to-date/TimestampToDateAction";
import JsonPrettyPrintingAction from "../actions/pretty-printing/JsonPrettyPrintingAction";
import Base64Action from "../actions/base64/Base64Action";
import Base64FromFileAction from "../actions/base64/Base64FromFileAction";
import UTF8Action from "../actions/utf8/UTF8Action";
import ComplementaryAction from "../actions/color/ComplementaryAction";
import UNFAction from "../actions/unf/UNFAction";
import MenuItem from './MenuItem'
import NumberToOrdinalAction from "../actions/number/to-ordinal/NumberToOrdinalAction";
import BundleSearchAction from "../actions/search/BundleSearchAction";

const Home = () => <Typography variant="h3" component="h1">Welcome to Small Code!!</Typography>

interface Item {
    name: string,
    langKey?: string,
    link?: string,
    Icon?: any,
    comp?: JSX.Element,
    items?: Item[],
}

// !!IMPORTANT The value of link property must be a globally unique.
const appMenuItems: Item[] = [
    {
        name: 'Home',
        langKey: 'home',
        link: '/',
        Icon: IconDashboard,
        comp: <Home />,
    },
    {
        name: 'General',
        Icon: IconShoppingCart,
        items: [
            {
                name: 'Roman',
                langKey: 'roman',
                link: '/roman',
                comp: <RomanAction />,
            },
            {
                name: 'Number to Ordinal',
                langKey: 'numberToOrdinal',
                link: '/number-to-ordinal',
                comp: <NumberToOrdinalAction />,
            },
            {
                name: 'Bundle Search',
                langKey: 'bundleSearch',
                link: '/bundle-search',
                comp: <BundleSearchAction />,
            },
        ],
    },
    {
        name: 'Developer',
        Icon: IconPeople,
        items: [
            {
                name: 'Levenshtein',
                langKey: 'levenshtein',
                link: '/lev',
                comp: <LevenshteinAction />,
            },
            {
                name: 'Base64',
                langKey: 'base64',
                link: '/base64',
                comp: <Base64Action />,
            },
            {
                name: 'Base64 From File',
                langKey: 'base64FromFile',
                link: '/base64-from-file',
                comp: <Base64FromFileAction />,
            },
            {
                name: 'UTF8',
                langKey: 'utf8',
                link: '/utf8',
                comp: <UTF8Action />,
            },
            {
                name: 'Simple Note',
                langKey: 'simpleNote',
                link: '/note',
                comp: <NoteAction />,
            },
            {
                name: 'Ticks to Date',
                langKey: 'tickToDate',
                link: '/ticks-to-date',
                comp: <TicksToDateAction />,
            },
            {
                name: 'Timestamp to Date',
                langKey: 'timestampToDate',
                link: '/timestamp-to-date',
                comp: <TimestampToDateAction />,
            },
            {
                name: 'Timezone',
                langKey: 'timezone',
                link: '/timezone',
                comp: <TimezoneAction />,
            },
            {
                name: 'New Line Converter',
                langKey: 'newline',
                link: '/line-convert',
                comp: <LineConvertAction />,
            },
            {
                name: 'Complementary Color',
                langKey: 'complementaryColor',
                link: '/complementary-color',
                comp: <ComplementaryAction />,
            },
            {
                name: 'Color Code',
                langKey: 'colorCode',
                link: '/color-code',
                comp: <ColorCodeAction />,
            },
            {
                name: 'Json Pretty Printing',
                langKey: 'jsonPretty',
                link: '/pretty-printing',
                comp: <JsonPrettyPrintingAction />,
            },
            {
                name: 'UNF Converting',
                langKey: 'unf',
                link: '/unf',
                comp: <UNFAction />,
            }
        ],
    },
    {
        name: 'About',
        langKey: 'about',
        link: '/about',
        Icon: IconBarChart,
        comp: <Home />,
    },
]

export function getItems(): Item[] {
    const result: Item[] = [];
    appMenuItems.forEach(menu => {
        const { items } = menu;
        if (items) {
            result.push(...items);
        } else {
            result.push(menu);
        }
    })
    return result;
}

const MENU_FIND_KEY_TYPE = {
    LINK: 'link',
    NAME: 'name',
}

export function getMenuPath(menuName: string) {
    let menuFullPath = getFullPath(MENU_FIND_KEY_TYPE.NAME, menuName);
    const { href } = window.location;
    const index = href.lastIndexOf('/');
    const link = href.substr(index);
    if (menuFullPath.length < 1 || menuFullPath[menuFullPath.length-1].link !== link) {
        menuFullPath = getFullPath(MENU_FIND_KEY_TYPE.LINK, link);
    }
    return menuFullPath;
}

export function getExpandedMenuName(currentMenuName: string) {
    const pathList = getFullPath(MENU_FIND_KEY_TYPE.NAME, currentMenuName);
    return pathList[0].name;
}

function getFullPath(keyType: string, value: string) {
    let result: Item[] = [];
    let found = false;
    const findMenu = (menu: Item, pathList: Item[]) => {
        const { name, link, items } = menu;
        if (items) {
            pathList.push(menu);
            items.forEach(subMenu => {
                findMenu(subMenu, pathList);
            });
        }
        if ((keyType === MENU_FIND_KEY_TYPE.NAME && name === value) ||
            (keyType === MENU_FIND_KEY_TYPE.LINK && link === value)) {
            pathList.push(menu);
            found = true;
        }
    }
    for (let i = 0; i < appMenuItems.length; i += 1) {
        result = [];
        findMenu(appMenuItems[i], result)
        if (found) {
            break;
        }
    }
    return result;
}

interface MenuProps {
    expandedMenuName: string,
}

function Menu(props: MenuProps) {
    const classes = useStyles()
    const { expandedMenuName } = props;

    return (
        <List component="nav" className={classes.appMenu} disablePadding>
            {/* <MenuItem {...appMenuItems[0]} /> */}
            {appMenuItems.map((item, index) => (
                <MenuItem {...item} key={index} expandedMenuName={expandedMenuName} />
            ))}
        </List>
    )
}

const drawerWidth = 240

const useStyles = makeStyles(theme =>
    createStyles({
        appMenu: {
            width: '100%',
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth,
        },
        menuItemIcon: {
            color: '#97c05c',
        },
    }),
)

export default Menu;
