import {Theme} from '@material-ui/core';

const styles = (theme: Theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(1, 0, 1, 0),
            },
            "& > div > textarea": {
                backgroundColor: "#fff",
            }
        },
    }
}

export default styles;
