import React, {useCallback, useState} from 'react';
import {Box, withStyles, WithStyles} from "@material-ui/core";
import {DateTimePicker, FormControlSelect} from '../common/custom-components';
import {convertTimeZone, TIME_ZONE_LIST, toStandardFormatDate} from '../ticks-to-date/date';
import styles from "./styles";

interface IResult {
    date: string,
    from: string,
    to: string,
    output: string,
}

interface TimezoneActionProps extends WithStyles<typeof styles> {
}

function TimezoneAction(props: TimezoneActionProps) {
    const [result, setResult] = useState<IResult>({
        date: '',
        from: 'Asia/Seoul',
        to: 'Asia/Seoul',
        output: '',
    })

    const handleDateChange = useCallback(e => {
        const {value} = e.target;
        const date = convertTimeZone(new Date(value), result.from, result.to);
        setResult({...result, date: value, output: toStandardFormatDate(date)});
    }, [result]);

    const handleFromTimezoneChange = useCallback(e => {
        const {value} = e.target;
        const date = convertTimeZone(new Date(result.date), value, result.to);
        setResult({...result, from: value, output: toStandardFormatDate(date)});
    }, [result]);

    const handleToTimezoneChange = useCallback(e => {
        const {value} = e.target;
        const date = convertTimeZone(new Date(result.date), result.from, value);
        setResult({...result, to: value, output: toStandardFormatDate(date)});
    }, [result]);

    const {classes} = props;

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center">
                <Box>
                    <DateTimePicker label="From Date" value={result.date} onChange={handleDateChange}/>
                </Box>
                <Box p={1}>
                    <FormControlSelect label="From Timezone" items={TIME_ZONE_LIST} value={result.from}
                                       onChange={handleFromTimezoneChange}/>
                </Box>
            </Box>
            <Box>
                <FormControlSelect label="To Timezone" items={TIME_ZONE_LIST} value={result.to}
                                   onChange={handleToTimezoneChange}/>
            </Box>
            <Box>
                <DateTimePicker label="Result Date" value={result.output} />
            </Box>
        </div>
    );
}

export default withStyles(styles)(TimezoneAction);
