function create2DArray(rows: number, columns: number): number[][] {
    return Array.from(Array(rows), () => Array(columns).fill(0))
}

export function calculate(origin: string[], test: string[]): number {
    let dist = create2DArray(origin.length + 1, test.length + 1);
    for (let i = 1; i <= origin.length; i++) {
        dist[i][0] = i;
    }

    for (let j = 1; j <= test.length; j++) {
        dist[0][j] = j;
    }

    for (let j = 1; j <= test.length; j++) {
        for (let i = 1; i <= origin.length; i++) {
            if (origin[i - 1] === test[j - 1]) {
                dist[i][j] = dist[i - 1][j - 1];
            } else {
                dist[i][j] = Math.min(dist[i - 1][j - 1] + 1, Math.min(dist[i][j - 1] + 1, dist[i - 1][j] + 1));
            }
        }
    }
    return (dist[origin.length])[test.length];
}