import {FormControl, FormHelperText, InputLabel, Select} from "@material-ui/core";
import React, {forwardRef} from "react";

interface FormControlSelectProps {
    label: string,
    items: any[],
    value: string,
    ref?: any,
    autoFocus?: boolean,
    readOnly?: boolean,
    helperText?: string,
    fullWidth?: boolean,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onChange?: (e: React.ChangeEvent<any>) => void,
}

const FormControlSelect = forwardRef<HTMLInputElement, FormControlSelectProps>((props, ref) => {
    const {label, items, value, autoFocus, readOnly, helperText, fullWidth, onChange, onFocus} = props;
    return (
        <FormControl variant="outlined" fullWidth={fullWidth}>
            <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
            <Select
                native
                value={value}
                label={label}
                margin="dense"
                autoFocus={autoFocus}
                readOnly={readOnly}
                onFocus={onFocus}
                onChange={onChange}
                // inputProps={{
                //     name: 'age',
                //     id: 'outlined-age-native-simple',
                // }}
            >
                {items.map(({name, value}, index) => (
                    <option key={name} value={value}>{name}</option>
                ))}
            </Select>
            {helperText && (
                <FormHelperText id="component-error-text">{helperText}</FormHelperText>
            )}
        </FormControl>
    )
});

export default FormControlSelect;


