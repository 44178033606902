import eol from "eol";

export enum LineType {
    CRLF,
    LF,
    CR,
}

export interface LineStatus {
    cr: number,
    lf: number,
    crlf: number,
}

export function analyze(text: string | undefined | null): Promise<LineStatus> {
    return new Promise<LineStatus>((resolve, reject) => {
        if (text) {
            let working = text;
            let crlf = (working.match(/\r\n/g) || []).length;
            working = working.replace(/\r\n/g, "");
            let lf = (working.match(/\n/g) || []).length;
            working = working.replace(/\n/g, "");
            let cr = (working.match(/\r/g) || []).length;
            let status = {cr: cr, crlf: crlf, lf: lf}
            resolve(status);
            return status;
        }
        reject();
    })
}

export function convert(text: string | undefined | null, toType: LineType): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        if (text) {
            let converted = "";
            switch (toType) {
                case LineType.CRLF:
                    converted = eol.crlf(text)
                    break;
                case LineType.LF:
                    converted = eol.lf(text)
                    break;
                case LineType.CR:
                    converted = eol.cr(text)
                    break;
            }
            resolve(converted);
        }
        reject();
    });
}