import "./NoteAction.css"
import {useEffect, useRef, useState} from "react";

export default function NoteAction() {
    let ref = useRef<HTMLDivElement>(null)
    let [elements, setElements] = useState<string|undefined>()

    useEffect(() => {
        let html = localStorage.getItem("cboat-note-data")
        if(html && ref.current) {
            ref.current.innerHTML = html
        }
    }, [ref])
    useEffect(() => {
        if (elements || elements === "") {
            localStorage.setItem("cboat-note-data", elements)
        }
    }, [elements])

    let updateElements = () => {
        let html = ref.current?.innerHTML;
        setElements(html);
    }

    return (
        <div>
            <p>Ctrl + B (굵게), Ctrl + I (이탤릭), Ctrl + U (밑줄)</p>
            <p>새로 고침하거나 브라우저 다시 띄워도 나와야함</p>
            <div className={"element"} onKeyUp={() => updateElements()} ref={ref}
                 contentEditable="true" suppressContentEditableWarning={true}>
            </div>
        </div>
    )
}