import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Language} from "../common/Types";
import {RootState} from '../modules';
import {setLanguage, setMenuName} from '../modules/common';

function useCommon() {
    // 상태를 조회합니다. 상태를 조회 할 때에는 state 의 타입을 RootState 로 지정해야합니다.
    const menuName = useSelector((state: RootState) => state.common.menuName);
    const language = useSelector((state: RootState) => state.common.language);
    const dispatch = useDispatch();

    const onSetMenuName = useCallback((link: string) => {
        return dispatch(setMenuName(link));
    }, [dispatch]);

    const onSetLanguage = useCallback((code: Language) => {
        return dispatch(setLanguage(code));
    }, [dispatch]);

    return {menuName, onSetMenuName, language, onSetLanguage};
}

export default useCommon;
