import React, {useMemo} from 'react'
import {Switch, Route} from 'react-router-dom'
import Layout from "./template/Layout";
import {getItems} from "./menu/Menu";
import {IntlProvider} from "react-intl";
import useCommon from "./hooks/useCommon";
import en from "./locales/en.json";
import ko from "./locales/ko.json";
import { Language } from "./common/Types";

function App() {
    let { language } = useCommon();
    const langSet = useMemo(() => {
        switch (language) {
            case Language.KO:
                return ko;
            case Language.EN:
                return en;
            default:
                return en;
        }
    }, [language])

    return (
        <IntlProvider locale={language} messages={langSet}>
            <Switch>
                {getItems().map(({name, link, langKey, comp}) => (
                    <Route key={name} exact path={link}>
                        {comp && (
                            <Layout langKey={langKey}>{comp}</Layout>
                        )}
                    </Route>
                ))}
            </Switch>
        </IntlProvider>
    );
}

export default App;
