import FileSaver from "file-saver";

interface Option {
    encoding: string
}

export function loadAsTextFromFiles(
    files: File[], callback: (file: File, content: string) => void, optionProcessor: ((name: string) => Option) | undefined = undefined
) {
    for (let file of files) {
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.result && typeof reader.result === "string") {
                callback(file, reader.result)
            }
        }
        if (optionProcessor) {
            let option = optionProcessor(file.name);
            if (option?.encoding) {
                reader.readAsText(file, option.encoding);
                continue;
            }
        }
        reader.readAsText(file);
    }
}

export function loadAsBinaryFromFiles(files: File[], callback: (file: File, content: ArrayBuffer) => void) {
    for (let file of files) {
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.result && reader.result instanceof ArrayBuffer) {
                callback(file, reader.result)
            }
        }
        reader.readAsArrayBuffer(file);
    }
}

export function saveToText(content: string, name: string, type: string = "text/plain;charset=utf-8") {
    saveTo(content, name, type);
}

export function saveToBinary(content: ArrayBuffer, name: string, type: string = "application/octet-stream") {
    saveTo(content, name, type);
}

export function saveTo(content: string | ArrayBuffer, name: string, type: string) {
    let blob = new Blob([content], {type: type});
    FileSaver.saveAs(blob, name);
}

export function arrayBufferToString(buffer: ArrayBuffer) {
    let array = new Uint16Array(buffer);
    return String.fromCharCode.apply(null, Array.from(array));
}

export function stringToArrayBuffer(str: string) {
    let buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    let bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}