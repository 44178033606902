import React, {useEffect, useState} from "react";
import {Checkbox, FormControlLabel, withStyles, WithStyles} from "@material-ui/core";
import styles from "./styles";
import FormControlInput from "../../common/custom-components/FormControlInput";
import {ordinal} from "./NumberFunction";


interface NumberToOrdinalActionProps extends WithStyles<typeof styles> {
}

function NumberToOrdinalAction(props: NumberToOrdinalActionProps) {
    let [numberTexts, setNumberTexts] = useState<string>("");
    let [ordinalTexts, setOrdinalTexts] = useState<string>("");
    let [advanced, setAdvanced] = useState<boolean>(true);

    useEffect(() => {
        let pattern = advanced ? /(?!([0-9^.]+(th|st|nd|rd)))[0-9^.]+/g : /[0-9^.]+/g
        let replaced = numberTexts.replace(pattern, m => {
            let target = m.trim();
            return ordinal(Number(target));
        })
        setOrdinalTexts(replaced);
    }, [numberTexts, advanced])

    return (
        <div className={props.classes.root}>
            <FormControlInput label={"Numbers"} value={numberTexts} multiline fullWidth onChange={(e) => {
                setNumberTexts(e.target.value)
            }}/>
            <FormControlInput label={"Ordinals"} value={ordinalTexts} multiline fullWidth readOnly={true} />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={advanced}
                        onChange={e => setAdvanced(e.target.checked)}
                        color="primary"
                    />
                }
                label="고급 분석"
            />
        </div>
    )
}

export default withStyles(styles)(NumberToOrdinalAction);