import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import clsx from "clsx";
import Menu, {getMenuPath, getExpandedMenuName} from "../menu/Menu";
import useCommon from "../hooks/useCommon";
import {Typography} from "@material-ui/core";

interface LayoutProps {
    langKey?: string,
    children: JSX.Element;
}

function Layout(props: LayoutProps) {
    const useMenu = String(process.env.REACT_APP_USE_MENU).toLowerCase() === "true";
    const useDescription = String(process.env.REACT_APP_USE_DESCRIPTION).toLowerCase() === "true";
    const classes = useStyles();
    const { menuName } = useCommon();
    const { messages } = useIntl();
    let menuPath = getMenuPath(menuName);
    const expandedMenuName = getExpandedMenuName(menuName || menuPath[0].name)

    const {langKey, children} = props;
    const headerText = messages[`${langKey}.desc`];

    return (
        <div className={clsx('App', classes.root)}>
            <CssBaseline/>
            {useMenu &&
            <Drawer variant="permanent" classes={{paper: classes.drawerPaper}}>
                <Menu expandedMenuName={expandedMenuName} />
            </Drawer>
            }
            <main className={classes.content}>
                <div className={classes.contentContainer}>
                    {useDescription &&
                    <div>
                        <div>
                            <h2 className={classes.contentPath}>{menuPath.map(menu => menu.name).join(' > ')}</h2>
                        </div>
                        <div className={classes.contentDesc}>
                            {headerText.toString().split('\n').map((text, index) => {
                                const key = `notice_${index}`;
                                return <Typography key={key}>{text}</Typography>
                            })}
                        </div>
                    </div>
                    }
                    {children}
                </div>
            </main>
        </div>
    )
}

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        background: '#535454',
        color: '#fff',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    contentContainer: {
        padding: theme.spacing(0, 2, 2, 2),
        // paddingTop: theme.spacing(2),
        // paddingBottom: theme.spacing(4),
        // paddingLeft: theme.spacing(4),
    },
    contentPath: {
        color: '#0c5894',
    },
    contentDesc: {
        height: '80px',
        boxShadow: '1px 2px 2px 1px rgba(0, 0, 0, 0.1)',
        borderRadius: theme.spacing(0.5),
        backgroundColor: 'white',
        marginBottom: theme.spacing(3),
        padding: theme.spacing(0.5, 1, 1, 2),
        '& p': {
            color: '#736c6c',
            fontSize: '0.9rem'
        }
    },
}))


export default Layout;
