import React, {useCallback, useState} from 'react';
import {withStyles, WithStyles} from "@material-ui/core";
import FormControlInput from "../common/custom-components/FormControlInput";
import {datetimeToTicks, ticksToDatetime} from "./TicksToDateFunction";
import styles from "./styles";
import DateTimePicker from '../common/custom-components/DateTimePicker';


interface TicksToDateActionProps extends WithStyles<typeof styles> {
}

interface IResult {
    ticks: string,
    datetime: string,
    t1: string,
    t2: string,
    error: string,
}

function TicksToDateAction(props: TicksToDateActionProps) {
    const [result, setResult] = useState<IResult>({
        t1: 'Asia/Seoul',
        t2: 'Asia/Seoul',
        ticks: '',
        datetime: '',
        error: '',
    })

    const handleTicksChange = useCallback(e => {
        const {value} = e.target;
        const datetime = ticksToDatetime(value);
        console.log(datetime);
        setResult({...result, ticks: value, datetime, error: datetime ? '' : 'Invalid thicks'});
    }, [result]);

    const handleDateTimeChange = useCallback(e => {
        const {value} = e.target;
        console.log(value);
        const ticks = datetimeToTicks(value);
        setResult({...result, ticks: ticks.toString(), datetime: value});
    }, [result]);

    const {classes} = props;

    return (
        <form className={classes.root}>
            <div>
                <FormControlInput label="ticks" value={result.ticks} autoFocus
                                  helperText={result.error}
                                  onChange={handleTicksChange}/>
            </div>
            <DateTimePicker label="DateTime" value={result.datetime} onChange={handleDateTimeChange}
            />
        </form>
    );
}

export default withStyles(styles)(TicksToDateAction);
