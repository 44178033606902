import {combineHangulJamo, divideHangulJamoDetail, Element, ResultDivideHangulJamo} from "./KoreanCommon";
import {pronounce} from "./KoreanPronunciation";

export let CH_CHO: string[] = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];
export let CH_JUN: string[] = ['ㅏ', 'ㅐ', 'ㅑ', 'ㅒ', 'ㅓ', 'ㅔ', 'ㅕ', 'ㅖ', 'ㅗ', 'ㅘ', 'ㅙ', 'ㅚ', 'ㅛ', 'ㅜ', 'ㅝ', 'ㅞ', 'ㅟ', 'ㅠ', 'ㅡ', 'ㅢ', 'ㅣ'];
export let CH_JON: string[] = [' ', 'ㄱ', 'ㄲ', 'ㄳ', 'ㄴ', 'ㄵ', 'ㄶ', 'ㄷ', 'ㄹ', 'ㄺ', 'ㄻ', 'ㄼ', 'ㄽ', 'ㄾ', 'ㄿ', 'ㅀ', 'ㅁ', 'ㅂ', 'ㅄ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

export let convertByElement = new Map<string, (curr: Element, next: Element | null) => string>([
    ['ㄱ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'g' : 'k'],
    ['ㄲ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'kk' : 'k'],
    ['ㄴ', (c, n) => 'n'],
    ['ㄷ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'd' : 't'],
    ['ㄸ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'tt' : ''],
    ['ㄹ', (c, n) => c === Element.CHO ? 'r' : 'l'],
    ['ㅁ', (c, n) => 'm'],
    ['ㅂ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'b' : 'p'],
    ['ㅃ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'pp' : ''],
    ['ㅅ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 's' : 't'],
    ['ㅆ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'ss' : 't'],
    ['ㅇ', (c, n) => c === Element.CHO ? '' : 'ng'],
    ['ㅈ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'j' : 't'],
    ['ㅉ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'jj' : ''],
    ['ㅊ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'ch' : 't'],
    ['ㅋ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'k' : 'k'],
    ['ㅌ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 't' : 't'],
    ['ㅍ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'p' : 'p'],
    ['ㅎ', (c, n) => (c === Element.CHO || (c === Element.JON && n === Element.JON)) ? 'h' : 't'],

    ['ㅏ', () => 'a'],
    ['ㅐ', () => 'ae'],
    ['ㅑ', () => 'ya'],
    ['ㅒ', () => 'yae'],
    ['ㅓ', () => 'eo'],
    ['ㅔ', () => 'e'],
    ['ㅕ', () => 'yeo'],
    ['ㅖ', () => 'ye'],
    ['ㅗ', () => 'o'],
    ['ㅘ', () => 'wa'],
    ['ㅙ', () => 'wae'],
    ['ㅚ', () => 'oe'],
    ['ㅛ', () => 'yo'],
    ['ㅜ', () => 'u'],
    ['ㅝ', () => 'wo'],
    ['ㅞ', () => 'we'],
    ['ㅟ', () => 'wi'],
    ['ㅠ', () => 'yu'],
    ['ㅡ', () => 'eu'],
    ['ㅢ', () => 'ui'],
    ['ㅣ', () => 'i'],

    ['ㄳ', () => 'k'],
    ['ㄵ', () => 'n'],
    ['ㄶ', () => 'n'],
    ['ㄺ', () => 'k'],
    ['ㄻ', () => 'm'],
    ['ㄼ', () => 'p'],
    ['ㄽ', () => 'l'],
    ['ㄾ', () => 'l'],
    ['ㄿ', () => 'p'],
    ['ㅀ', () => 'l'],
    ['ㅄ', () => 'p']
])

export function convertByException(curr: Element, next: Element | null, currJamo: string, nextJamo: string | null): { handles: number, converted: string } {
    let handles = 0;
    let converted = '';
    if (currJamo === 'ㄹ' && nextJamo === 'ㄹ') {
        converted = 'll';
        handles += 2;
    }
    return {handles: handles, converted: converted}
}

export function convertToRoman(text: string): {
    source: ResultDivideHangulJamo, roman: string
} {
    let source = divideHangulJamoDetail(text);
    let romanized = "";
    let divided = source.divided.split('');
    for (let i = 0; i < divided.length; i++) {
        let currJamo = divided[i];
        let nextJamo = i + 1 >= divided.length ? null : divided[i + 1];
        let curr = source.elements[i];
        let next = i + 1 >= divided.length ? null : source.elements[i + 1];

        let convException = convertByException(curr, next, currJamo, nextJamo);
        if (convException.handles > 0) {
            romanized += convException.converted;
            i++;
            continue;
        }

        let convElement = convertByElement.get(currJamo);
        if (convElement) {
            romanized += convElement(curr, next);
        } else {
            romanized += currJamo;
        }
    }
    return {
        source: source,
        roman: romanized
    }
}

let ROMAN_1_4_ADD = new Map([['ㄲ', 'ㄱ'], ['ㄸ', 'ㄷ'], ['ㅃ', 'ㅂ'], ['ㅆ', 'ㅅ'], ['ㅉ', 'ㅈ']]);

export function convertPronunciationForRoman(text: string): { pronunciation: string, pronunciationForRoman: string } {
    let pronounced = pronounce(text)
    let source: string[] = []
    for (let i = 0; i < pronounced.pronunciation.length; i++) {
        let ch = pronounced.notation[i];
        let pn = pronounced.pronunciation[i];
        let ele = pronounced.source.elements[i];
        if (ch === 'ㅢ') {
            source[i] = pronounced.notation[i];
            continue;
        }

        let doensoliByPn = ROMAN_1_4_ADD.get(pn);
        let doensoliByCh = ROMAN_1_4_ADD.get(ch);
        if (ele === Element.CHO && !doensoliByCh && doensoliByPn) {
            source[i] = doensoliByPn;
            continue;
        }
        source[i] = pronounced.pronunciation[i];
    }
    return {
        pronunciation: combineHangulJamo(pronounced.pronunciation, pronounced.source.elements),
        pronunciationForRoman: combineHangulJamo(source, pronounced.source.elements)
    }
}