import React, {useCallback, useRef, useState} from 'react';
import {Box, WithStyles, withStyles} from "@material-ui/core";
import {FormControlInput, OutlinedButton} from '../common/custom-components';
import {RGBToHex, hexToRGB, RGBToHSL, RGBtoCMYK} from "./ColorFunction";
import styles from "./styles";

interface ColorCodeActionProps extends WithStyles<typeof styles> {
}

function ColorCodeAction(props: ColorCodeActionProps) {
    const [color, setColor] = useState({
        r: 0, g: 0, b: 0
    });
    const [hex, setHex] = useState('#000000');
    const hexInput = useRef<HTMLInputElement>(null);
    const rgbInput = useRef<HTMLInputElement>(null);
    const hslInput = useRef<HTMLInputElement>(null);
    const cmykInput = useRef<HTMLInputElement>(null);

    const handleRgbChange = useCallback(e => {
        const {name, value} = e.target;
        const newColor = {...color, [name]: value};
        setColor(newColor);
        setHex(RGBToHex(newColor.r, newColor.g, newColor.b))
    }, [color]);

    const handleInputColorChange = useCallback(e => {
        const {value} = e.target;
        setColor(hexToRGB(value));
        setHex(value);
    }, []);

    const handleHexChange = useCallback(e => {
        const {value} = e.target;
        setColor(hexToRGB(value));
        setHex(value);
    }, []);

    const copyToClipboard = (target: any) => {
        target.select();
        target.setSelectionRange(0, 99999);
        document.execCommand("copy");
    };

    const handleHexCopyClick = useCallback(e => {
        copyToClipboard(hexInput.current);
    }, []);

    const handleRgbCopyClick = useCallback(e => {
        copyToClipboard(rgbInput.current);
    }, []);

    const handleCmykCopyClick = useCallback(e => {
        copyToClipboard(cmykInput.current);
    }, []);

    const handleHslCopyClick = useCallback(e => {
        copyToClipboard(hslInput.current);
    }, []);

    const rgbCode = `rgb(${color.r},${color.g},${color.b})`;
    const hsl = RGBToHSL(color.r, color.g, color.b);
    const hslCode = `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
    const cmyk = RGBtoCMYK(color.r, color.g, color.b);
    const cmykCode = `cmyk(${cmyk.c}, ${cmyk.m}, ${cmyk.y}, ${cmyk.k})`;

    const {classes} = props;

    return (
        <div className={classes.root}>
            {Object.keys(color).map(key => {
                const value = (color as any)[key];
                return (
                    <Box key={key} display="flex" alignItems="center">
                        <Box>
                            <FormControlInput label={key.toUpperCase()} value={value} autoFocus
                                              onChange={handleRgbChange}/>
                        </Box>
                        <Box>
                            <input name={key} className="slider" type="range" min="0" max="255" value={value}
                                   onChange={handleRgbChange}/>
                        </Box>
                    </Box>
                )
            })}
            <div>
                <input className={classes.colorPicker} type="color" value={hex} onChange={handleInputColorChange}/>
            </div>
            <Box display="flex" alignItems="center">
                <Box>
                    <FormControlInput label="HEX" ref={hexInput} value={hex} onChange={handleHexChange}/>
                </Box>
                <Box p={1}>
                    <OutlinedButton label="COPY" onClick={handleHexCopyClick}/>
                </Box>
            </Box>
            <Box display="flex" alignItems="center">
                <Box>
                    <FormControlInput label="RGB" ref={rgbInput} value={rgbCode} readOnly/>
                </Box>
                <Box p={1}>
                    <OutlinedButton label="COPY" onClick={handleRgbCopyClick}/>
                </Box>
            </Box>
            <Box display="flex" alignItems="center">
                <Box>
                    <FormControlInput label="CMYK" ref={cmykInput} value={cmykCode} readOnly/>
                </Box>
                <Box p={1}>
                    <OutlinedButton label="COPY" onClick={handleCmykCopyClick}/>
                </Box>
            </Box>
            <Box display="flex" alignItems="center">
                <Box>
                    <FormControlInput label="HSL" ref={hslInput} value={hslCode} readOnly/>
                </Box>
                <Box p={1}>
                    <OutlinedButton label="COPY" onClick={handleHslCopyClick}/>
                </Box>
            </Box>
        </div>
    );
}

export default withStyles(styles)(ColorCodeAction);
