
export function toStandardFormatDate(date: Date): string {
    const y = date.getFullYear().toString().padStart(4, '0');
    const M = (date.getMonth() + 1).toString().padStart(2, '0');
    const d = date.getDate().toString().padStart(2, '0');
    const h = date.getHours().toString().padStart(2, '0');
    const m = date.getMinutes().toString().padStart(2, '0');
    const s = date.getSeconds().toString().padStart(2, '0');
    const sss = date.getMilliseconds().toString().padStart(3, '0');
    return `${y}-${M}-${d}T${h}:${m}:${s}.${sss}`;
}

export function isValidDate(d: any): boolean {
    if (Object.prototype.toString.call(d) === "[object Date]") {
        return !isNaN(d.getTime());
    } else {
        return false;
    }
}

export const TIME_ZONE_LIST = [
    {name: 'UTC', value: 0},
    {name: 'Asia/Ho_Chi_Minh', value: 7},
    {name: 'Asia/Seoul', value: 9},
    {name: 'Asia/Shanghai', value: 8},
    {name: 'Asia/Singapore', value: 8},
    {name: 'Asia/Taipei', value: 8},
];

function getTimeZoneValue(targetZone: string) {
    const found = TIME_ZONE_LIST.find(({name}) => name === targetZone);
    if (found) {
        return found.value;
    }
    return 0;
}

export function convertTimeZone(target: Date, from: string, to: string): Date {
    if (from !== TIME_ZONE_LIST[0].name) {
        target.setHours(target.getHours() - getTimeZoneValue(from));
    }
    if (to !== TIME_ZONE_LIST[0].name) {
        target.setHours(target.getHours() + getTimeZoneValue(to));
    }
    return target;
}
