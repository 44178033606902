function hexToBytesArray(source: string) {
    const hexArray = source.split('\\x');
    const initialValue: number[] = [];
    return hexArray.reduce((prev, current) => {
        for (let c = 0; c < current.length; c += 2) {
            prev.push(parseInt(current.substr(c, 2), 16));
        }
        return prev;
    }, initialValue)
}

function bytesToHexStr(bytes: number[]) {
    const hex: string[] = [];
    for (let i = 0; i < bytes.length; i += 1) {
        const first = (bytes[i] >>> 4).toString(16);
        const second =  (bytes[i] & 0xF).toString(16);
        const c = `\\x${first}${second}`;
        hex.push(c);
    }
    return hex.join("");
}

export function stringToUnicode(source: string): string {
    let unicodeString = "";
    for (let ch of source) {
        let code = ch.codePointAt(0);
        if(code) {  // TODO: undefined 일 때 예외처리를 해야하남...
            let hex = code.toString(16).toUpperCase();
            unicodeString += `\\u${("000000" + hex).slice(-(Math.max(4, hex.length)))}`
        }
    }
    return unicodeString;
}

export function stringToUtf8(source: string): string {
    let i = 0;
    const bytes = new Uint8Array(source.length * 4);
    for (let ci = 0; ci !== source.length; ci++) {
        let c = source.charCodeAt(ci);
        if (c < 128) {
            bytes[i++] = c;
            continue;
        }
        if (c < 2048) {
            bytes[i++] = (c >> 6) | 192;
        } else {
            if (c > 0xd7ff && c < 0xdc00) {
                if (++ci >= source.length)
                    throw new Error('UTF-8 encode: incomplete surrogate pair');
                const c2 = source.charCodeAt(ci);
                if (c2 < 0xdc00 || c2 > 0xdfff)
                    throw new Error('UTF-8 encode: second surrogate character 0x' + c2.toString(16) + ' at index ' + ci + ' out of range');
                c = 0x10000 + ((c & 0x03ff) << 10) + (c2 & 0x03ff);
                bytes[i++] = (c >> 18) | 240;
                bytes[i++] = ((c >> 12) & 63) | 128;
            } else bytes[i++] = (c >> 12) | 224;
            bytes[i++] = ((c >> 6) & 63) | 128;
        }
        bytes[i++] = (c & 63) | 128;
    }
    return bytesToHexStr(Array.from(bytes.subarray(0, i)));
}

export function utf8ToString(source: string): string {
    let out = "";
    let i;
    let len;
    let c: any;

    const array = hexToBytesArray(source);

    out = "";
    len = array.length;
    i = 0;

    // XXX: Invalid bytes are ignored
    while (i < len) {
        c = array[i++];
        if (c >> 7 === 0) {
            // 0xxx xxxx
            out += String.fromCharCode(c);
            continue;
        }

        // Invalid starting byte
        if (c >> 6 === 0x02) {
            continue;
        }

        // #### MULTIBYTE ####
        // How many bytes left for thus character?
        var extraLength = null;
        if (c >> 5 === 0x06) {
            extraLength = 1;
        } else if (c >> 4 === 0x0e) {
            extraLength = 2;
        } else if (c >> 3 === 0x1e) {
            extraLength = 3;
        } else if (c >> 2 === 0x3e) {
            extraLength = 4;
        } else if (c >> 1 === 0x7e) {
            extraLength = 5;
        } else {
            continue;
        }

        // Do we have enough bytes in our data?
        if (i + extraLength > len) {
            // const leftovers = array.slice(i-1);

            // If there is an invalid byte in the leftovers we might want to
            // continue from there.
            for (; i < len; i++) if (array[i] >> 6 !== 0x02) break;
            if (i !== len) continue;

            // All leftover bytes are valid.
            // return {result: out, leftovers: leftovers};
            return out;
        }
        // Remove the UTF-8 prefix from the char (res)
        const mask = (1 << (8 - extraLength - 1)) - 1;
        let res: number = c & mask;
        let nextChar = 0;
        let count;

        for (count = 0; count < extraLength; count++) {
            nextChar = array[i++];

            // Is the char valid multibyte part?
            if (nextChar >> 6 !== 0x02) {
                break;
            }
            res = (res << 6) | (nextChar & 0x3f);
        }

        if (count !== extraLength) {
            i--;
            continue;
        }

        if (res <= 0xffff) {
            out += String.fromCharCode(res);
            continue;
        }

        res -= 0x10000;
        const high = ((res >> 10) & 0x3ff) + 0xd800,
            low = (res & 0x3ff) + 0xdc00;
        out += String.fromCharCode(high, low);
    }

    // return {result: out, leftovers: []};
    return out;
}

// Unmarshals a string from an Uint8Array.
// function decodeUTF8(bytes) {
//     var i = 0, s = '';
//     while (i < bytes.length) {
//         var c = bytes[i++];
//         if (c > 127) {
//             if (c > 191 && c < 224) {
//                 if (i >= bytes.length)
//                     throw new Error('UTF-8 decode: incomplete 2-byte sequence');
//                 c = (c & 31) << 6 | bytes[i++] & 63;
//             } else if (c > 223 && c < 240) {
//                 if (i + 1 >= bytes.length)
//                     throw new Error('UTF-8 decode: incomplete 3-byte sequence');
//                 c = (c & 15) << 12 | (bytes[i++] & 63) << 6 | bytes[i++] & 63;
//             } else if (c > 239 && c < 248) {
//                 if (i + 2 >= bytes.length)
//                     throw new Error('UTF-8 decode: incomplete 4-byte sequence');
//                 c = (c & 7) << 18 | (bytes[i++] & 63) << 12 | (bytes[i++] & 63) << 6 | bytes[i++] & 63;
//             } else throw new Error('UTF-8 decode: unknown multibyte start 0x' + c.toString(16) + ' at index ' + (i - 1));
//         }
//         if (c <= 0xffff) s += String.fromCharCode(c);
//         else if (c <= 0x10ffff) {
//             c -= 0x10000;
//             s += String.fromCharCode(c >> 10 | 0xd800)
//             s += String.fromCharCode(c & 0x3FF | 0xdc00)
//         } else throw new Error('UTF-8 decode: code point 0x' + c.toString(16) + ' exceeds UTF-16 reach');
//     }
//     return s;
// }
