import FormControlInput from "../common/custom-components/FormControlInput";
import React, {useEffect, useState} from "react";
import styles from "./styles";
import {WithStyles, withStyles} from "@material-ui/core";

interface UNFActionProps extends WithStyles<typeof styles> {
}

enum Mode {
    NFC_TO_NFD,
    NFD_TO_NFC
}

function UNFAction(props: UNFActionProps) {
    const {classes} = props;
    let [nfcText, setNFCText] = useState("");
    let [nfdText, setNFDText] = useState("");
    let [nfkcText, setNFKCText] = useState("");
    let [nfkdText, setNFKDText] = useState("");
    let [mode, setMode] = useState(Mode.NFC_TO_NFD);

    useEffect(() => {
        if(mode === Mode.NFC_TO_NFD) {
            setNFDText(nfcText.normalize("NFD"));
            setNFKCText(nfcText.normalize("NFKC"));
            setNFKDText(nfcText.normalize("NFKD"));
        }
    }, [nfcText, mode])

    useEffect(() => {
        if(mode === Mode.NFD_TO_NFC) {
            setNFCText(nfdText.normalize("NFC"));
            setNFKCText(nfdText.normalize("NFKC"));
            setNFKDText(nfdText.normalize("NFKD"));
        }
    }, [nfdText, mode])

    return (
        <div>
            <form className={classes.root}>
                <FormControlInput label="NFC" value={nfcText} multiline fullWidth autoFocus rows={6}
                                  onFocus={() => setMode(Mode.NFC_TO_NFD)}
                                  onChange={(e) => setNFCText(e.target.value)}/>
                <FormControlInput label="NFD" value={nfdText} multiline fullWidth rows={6}
                                  onFocus={() => setMode(Mode.NFD_TO_NFC)}
                                  onChange={(e) => setNFDText(e.target.value)}/>
                <FormControlInput label="NFKC" value={nfkcText} multiline fullWidth rows={6} readOnly={true}
                                  onChange={(e) => setNFKCText(e.target.value)}/>
                <FormControlInput label="NFKD" value={nfkdText} multiline fullWidth rows={6} readOnly={true}
                                  onChange={(e) => setNFKDText(e.target.value)}/>
            </form>
        </div>
    )
}

export default withStyles(styles)(UNFAction);
