import {CH_CHO, CH_JON, CH_JUN} from "./KoreanRomanNotation";

export enum Element {
    CHO,
    JUN,
    JON,
    UNK
}


export interface ResultDivideHangulJamo {
    divided: string,
    elements: Element[],
    indexes: (number[])[]
}

export function divideHangulJamoDetail(text: string): ResultDivideHangulJamo {
    let builder = "";
    let elements: Element[] = []
    let indexes: (number[])[] = []
    for (let i = 0; i < text.length; i++) {
        let ch = text.charCodeAt(i);
        if (ch >= 0xAC00) {
            let val = ch - 0xAC00;
            let cho = Math.floor(Math.floor(((val - (val % 28)) / 28)) / 21);
            let jun = Math.floor((val - (val % 28)) / 28) % 21;
            let jon = val % 28;

            builder += CH_CHO[cho];
            elements.push(Element.CHO);

            builder += CH_JUN[jun];
            elements.push(Element.JUN);

            if (CH_JON[jon] !== ' ') {
                builder += CH_JON[jon];
                elements.push(Element.JON);
            }
            indexes.push([cho, jun, jon])
        } else {
            builder += text.charAt(i);
            elements.push(Element.UNK);
            indexes.push([])
        }
    }
    return {
        divided: builder, elements: elements, indexes: indexes
    }
}

export function divideHangulJamo(text: string): string {
    return divideHangulJamoDetail(text).divided
}

export function combineHangulJamo(divided: string[], elements: Element[]): string {
    let choIdx = 0
    let junIdx = 0
    let jonIdx = 0
    let result: string[] = []
    for (let i = 0; i < divided.length; i++) {
        let ch = divided[i];
        let element = elements[i];

        if (element === Element.CHO) {
            choIdx = CH_CHO.indexOf(ch)
        } else if (element === Element.JUN) {
            junIdx = CH_JUN.indexOf(ch)
            if (i + 1 >= divided.length || elements[i + 1] !== Element.JON) {
                result.push(String.fromCharCode(0xAC00 + 28 * 21 * choIdx + 28 * junIdx))
                choIdx = 0;
                junIdx = 0;
                jonIdx = 0;
            }
        } else if (elements[i] === Element.JON) {
            jonIdx = CH_JON.indexOf(ch);
            result.push(String.fromCharCode(0xAC00 + 28 * 21 * choIdx + 28 * junIdx + jonIdx))
            choIdx = 0;
            junIdx = 0;
            jonIdx = 0;
        } else {
            result.push(ch)
            choIdx = 0;
            junIdx = 0;
            jonIdx = 0;
        }
    }
    return result.join('');
}