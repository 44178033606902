import React, {useEffect, useState} from "react";
import {Checkbox, FormControl, FormControlLabel, withStyles} from "@material-ui/core";
import { WithStyles } from '@material-ui/core/styles';
import styles from "./styles";
import "./LevenshteinAction.css"
import {calculate} from "./LevenshteinFunction";
import {divideHangulJamo} from "../../common/language/ko/KoreanCommon";
import FormControlInput from "../common/custom-components/FormControlInput";


interface LevenshteinActionProps extends WithStyles<typeof styles> {
}

function LevenshteinAction(props: LevenshteinActionProps) {
    let [originText, setOriginText] = useState("");
    let [othersText, setOthersText] = useState("");
    let [divideText, setDivideText] = useState("");
    let [distance, setDistance] = useState("");
    let [needDivide, setNeedDivide] = useState(false);
    const { classes } = props;

    useEffect(() => {
        setDivideText(divideHangulJamo(originText));
    }, [originText])

    useEffect(() => {
        let distance = calculate(
            (needDivide ? divideHangulJamo(originText) : originText).split(''),
            (needDivide ? divideHangulJamo(othersText) : othersText).split('')
        );
        setDistance(distance.toString());

    }, [needDivide, originText, othersText])

    return (
        <form className={classes.root}>
            <FormControlInput label="원문 텍스트" value={originText} multiline fullWidth autoFocus
                              onChange={e => setOriginText(e.target.value)} />
            <FormControlInput label="비교 대상 텍스트" value={othersText} multiline fullWidth
                              onChange={e => setOthersText(e.target.value)} />
            <FormControlInput label="자소 분리한 내용" value={divideText} multiline fullWidth
                              onChange={e => setDivideText(e.target.value)} />
            <FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={needDivide}
                            onChange={e => setNeedDivide(e.target.checked)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="한글 자소 분리 기준 거리 계산"
                />
                <div>
                    <label>편집 거리 : {distance}</label>
                </div>
            </FormControl>
        </form>
    )
}

export default withStyles(styles)(LevenshteinAction);
