import React, {useEffect, useState} from "react";
import { getComplementaryColor } from "./ColorFunction";

export default function ComplementaryAction() {
    let [originColor, setOriginColor] = useState<string>()
    let [complementaryColor, setComplementaryColor] = useState<string>()

    useEffect(() => {
        if (originColor) {
            let v = getComplementaryColor(originColor)
            setComplementaryColor(v);
        }
    }, [originColor])

    let updateColor = (colorText: string) => {
        setOriginColor(colorText)
    }

    return (
        <div>
            <label>Select your favorite color: {originColor}</label>
            <div style={{display: "inline-block", width: "10px"}}/>
            <input type="color" id="originColor" name="originColor" value={originColor}
                   onChange={e => updateColor(e.target.value)}/>
            <br/>
            <label>Complementary Color: {complementaryColor}</label>
            <div style={{display: "inline-block", width: "10px"}}/>
            <div style={{
                display: "inline-block",
                position: "relative",
                top: "5px",
                width: "100px",
                height: "20px",
                backgroundColor: complementaryColor
            }}/>
        </div>
    )
}
