import React, {useCallback, useState} from 'react';
import {withStyles, WithStyles} from "@material-ui/core/styles";
import FormControlInput from "../common/custom-components/FormControlInput";
import styles from './styles';
import {stringToUnicode, stringToUtf8, utf8ToString} from './UTF8Function';

interface UTF8ActionProps extends WithStyles<typeof styles> {
}

function UTF8Action(props: UTF8ActionProps) {
    const [result, setResult] = useState({
        decoded: '',
        encoded: '',
        unicode: '',
    })

    const handleDecodedChange = useCallback(e => {
        const encoded = stringToUtf8(e.target.value);
        const unicode = stringToUnicode(e.target.value);
        setResult({encoded, decoded: e.target.value, unicode: unicode})
    }, []);

    const handleEncodedChange = useCallback(e => {
        const decoded = utf8ToString(e.target.value);
        const unicode = stringToUnicode(decoded);
        setResult({encoded: e.target.value, decoded, unicode: unicode})
    }, []);

    const {classes} = props;

    return (
        <form className={classes.root}>
            <FormControlInput label="원문 텍스트" value={result.decoded} multiline fullWidth onChange={handleDecodedChange} autoFocus />
            <FormControlInput label="UTF8 Encoded" value={result.encoded} multiline fullWidth onChange={handleEncodedChange} />
            <FormControlInput label="Unicode" value={result.unicode} multiline fullWidth readOnly />
        </form>
    );
}

export default withStyles(styles)(UTF8Action);
