import {divideHangulJamoDetail, Element, ResultDivideHangulJamo} from "./KoreanCommon";

class ResultJ2P {
    public curr: string | null;
    public next: string | null;

    constructor(curr: string | null, next: string | null) {
        this.curr = curr;
        this.next = next;
    }

    static create(curr: string | null, next: string | null | undefined): ResultJ2P {
        if(next === undefined) {
            next = null;
        }
        return new ResultJ2P(curr, next);
    }
}

let PRONUNCIATION_4_15 = ['ㅏ', 'ㅓ', 'ㅗ', 'ㅜ', 'ㅟ'];
let PRONUNCIATION_5_18 = ['ㄴ', 'ㅁ'];
let PRONUNCIATION_6_23 = ['ㄱ', 'ㄷ', 'ㅂ', 'ㅅ', 'ㅈ'];
let PRONUNCIATION_6_24_25 = ['ㄱ', 'ㄷ', 'ㅅ', 'ㅈ'];
let PRONUNCIATION_6_26 = ['ㄷ', 'ㅅ', 'ㅈ'];
let PRONUNCIATION_7_29 = ['ㅣ', 'ㅑ', 'ㅕ', 'ㅛ', 'ㅠ'];
let MAP_DOENSOLI = new Map([['ㄱ', 'ㄲ'], ['ㄷ', 'ㄸ'], ['ㅂ', 'ㅃ'], ['ㅅ', 'ㅆ'], ['ㅈ', 'ㅉ']]);

let PRONUNCIATION_EX = new Map([
    ['디귿이', '디그시'], ['디귿을', '디그슬'], ['디귿에', '디그세'],     // 16항 예외1
    ['지읒이', '지으시'], ['지읒을', '지으슬'], ['지읒에', '지으세'],     // 16항 예외1
    ['치읓이', '치으시'], ['치읓을', '치으슬'], ['치읓에', '치으세'],     // 16항 예외1
    ['키읔이', '키으기'], ['키읔을', '키으글'], ['키읔에', '키으게'],     // 16항 예외1
    ['티읕이', '티으시'], ['티읕을', '티으슬'], ['티읕에', '티으세'],     // 16항 예외1
    ['피읖이', '피으비'], ['피읖을', '피으블'], ['피읖에', '피으베'],     // 16항 예외1
    ['히읗이', '히으시'], ['히읗을', '히으슬'], ['히읗에', '히으세'],     // 16항 예외1

    ['의견란', '의견난'], ['임진란', '임진난'], ['생산량', '생산냥'], ['결단력', '결딴녁'],    // 20항 예외1
    ['공권력', '공꿘녁'], ['동원령', '동원녕'], ['상견례', '상견녜'], ['횡단로', '횡단노'],    // 20항 예외1
    ['이원론', '이원논'], ['입원료', '이붠뇨'], ['구근류', '구근뉴'],                         // 20항 예외1

    ['6·25', '유기오'], ['육이오', '유기오'], ['3·1절', '사밀쩔'], ['삼일절', '사밀쩔'], ['송별연', '송벼련'], ['등용문', '등용문']       // 29항 예외1
]);

let CONV_PRONUNCIATION = new Map<string, (curr: string, next: string | null, after: string | null) => ResultJ2P>([
    ['ㄱ', (curr, next, after) => {
        if (next === 'ㅎ') return ResultJ2P.create(null, 'ㅋ');  // 4장 12항 1번 붙힘1
        if (next === 'ㅇ') {
            if(after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next === 'ㄹ') next = 'ㄴ';                          // 5장 19항 붙임1
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅇ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create(curr, next);
    }],
    ['ㄴ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after === 'ㅣ') {
                return ResultJ2P.create(null, curr);            // 4장 13항
            }
            if(after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
        }
        if (next === 'ㄹ') return ResultJ2P.create('ㄹ', next);  // 5장 20항
        if (next && PRONUNCIATION_6_24_25.includes(next) && !(next === 'ㄱ' && after ==='ㅣ')) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 24항
        return ResultJ2P.create(curr, next);
    }],
    ['ㄷ', (curr, next, after) => {
        if (next === 'ㅎ') {
            if (after === 'ㅣ') {
                return ResultJ2P.create(null, 'ㅊ')              // 5장 17항 붙임1
            }
            return ResultJ2P.create(null, 'ㅌ');                 // 4장 12항 1번 붙힘1
        }
        if (next === 'ㅇ') {
            if (after === 'ㅣ') {
                return ResultJ2P.create(null, 'ㅈ')              // 5장 17항
            }
            if(after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);  // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㄴ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create(curr, next);
    }],
    ['ㄹ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if(after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄹ');             // 장 29항 붙임1
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next === 'ㄴ') return ResultJ2P.create(curr, 'ㄹ');  // 5장 20항
        if (next && PRONUNCIATION_6_26.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 26항
        return ResultJ2P.create(curr, next);
    }],
    ['ㅁ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if(after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next === 'ㄹ') return ResultJ2P.create(curr, 'ㄴ');  // 5장 19항
        if (next && PRONUNCIATION_6_24_25.includes(next) && !(next === 'ㄱ' && after ==='ㅣ')) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 24항
        return ResultJ2P.create(curr, next);
    }],
    ['ㅂ', (curr, next, after) => {
        if (next === 'ㅎ') return ResultJ2P.create(null, 'ㅍ');  // 4장 12항 1번 붙힘1
        if (next === 'ㅇ') {
            if(after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next === 'ㄹ') next = 'ㄴ';                          // 5장 19항 붙임1
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅁ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create(curr, next);
    }],
    ['ㅅ', (curr, next, after) => {
        if (next === 'ㅎ') return ResultJ2P.create(null, 'ㅌ');  // 4장 12항 1번 붙힘2
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄷ');             // 4장 15항
            }
            if (after === 'ㅣ') {
                return ResultJ2P.create('ㄴ', 'ㄴ');             // 30항 3
            }
            if(after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㄴ', next);  // 5장 18항, 7장 30항
        if (next && PRONUNCIATION_6_23.includes(next)) {
            return ResultJ2P.create(null, MAP_DOENSOLI.get(next)); // 6장 23항, 7장 30항
        }
        return ResultJ2P.create('ㄷ', next);                     // 4장 9항
    }],
    ['ㅇ', (curr, next) => {
        if (next === 'ㅇ') return ResultJ2P.create(curr, curr);  // 4장 13항
        if (next === 'ㄹ') return ResultJ2P.create(curr, 'ㄴ');  // 5장 19항
        return ResultJ2P.create(curr, next);
    }],
    ['ㅈ', (curr, next, after) => {
        if (next === 'ㅎ') return ResultJ2P.create(null, 'ㅌ');  // 4장 12항 1번 붙힘2
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄷ');             // 4장 15항
            }
            if (after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㄴ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄷ', next);                     // 4장 9항
    }],
    ['ㅊ', (curr, next, after) => {
        if (next === 'ㅎ') return ResultJ2P.create(null, 'ㅌ');  // 4장 12항 1번 붙힘2
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄷ');             // 4장 15항
            }
            if (after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㄴ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄷ', next);                     // 4장 9항
    }],
    ['ㅋ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄱ');             // 4장 15항
            }
            if (after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅇ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄱ', next);                     // 4장 9항
    }],
    ['ㅌ', (curr, next, after) => {
        if (next === 'ㅎ') return ResultJ2P.create(null, 'ㅌ');  // 4장 12항 1번 붙힘2
        if (next === 'ㅇ') {
            if (after === 'ㅣ') {
                return ResultJ2P.create(null, 'ㅊ')              // 5장 17항
            }
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄷ');             // 4장 15항
            }
            if (after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㄴ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄷ', next);                     // 4장 9항
    }],
    ['ㅍ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㅂ');             // 4장 15항
            }
            if (after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅁ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create(curr, MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㅂ', next);                     // 4장 9항
    }],
    ['ㅎ', (curr, next, after) => {
        if (next === 'ㄱ') return ResultJ2P.create(null, 'ㅋ');  // 4장 12항 1번
        if (next === 'ㄷ') return ResultJ2P.create(null, 'ㅌ');  // 4장 12항 1번
        if (next === 'ㅈ') return ResultJ2P.create(null, 'ㅊ');  // 4장 12항 1번
        if (next === 'ㅅ') return ResultJ2P.create(null, 'ㅆ');  // 4장 12항 2번
        if (next === 'ㄴ') return ResultJ2P.create('ㄴ', 'ㄴ');  // 4장 12항 3번
        if (next === 'ㅇ') {
            if(after && PRONUNCIATION_7_29.includes(after)) {   // 7장 29항
                return ResultJ2P.create(curr, 'ㄴ');
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㄴ', next);  // 5장 18항
        return ResultJ2P.create('ㅎ', next);
    }],
    ['ㄲ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄱ');             // 4장 15항
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅇ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create('ㄱ', MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄱ', next);                     // 4장 9항
    }],
    ['ㅆ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄷ');             // 4장 15항
            }
            return ResultJ2P.create(null, curr);                 // 4장 13항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㄴ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create('ㄷ', MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄷ', next);                     // 4장 9항
    }],
    ['ㄳ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄱ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄱ', 'ㅆ');                 // 4장 14항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅇ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create('ㄱ', MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄱ', next);                     // 4장 10항
    }],
    ['ㄵ', (curr, next, after) => {

        if (next === 'ㅎ') return ResultJ2P.create('ㄴ', 'ㅊ');  // 4장 12항 1번 붙힘1
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄴ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄴ', 'ㅈ');                 // 4장 14항
        }
        if (next && PRONUNCIATION_6_24_25.includes(next) && !(next === 'ㄱ' && after ==='ㅣ')) return ResultJ2P.create('ㄴ', MAP_DOENSOLI.get(next));    // 6장 24항
        return ResultJ2P.create('ㄴ', next);                     // 4장 10항
    }],
    ['ㄶ', (curr, next) => {
        if (next === 'ㄱ') return ResultJ2P.create('ㄴ', 'ㅋ');  // 4장 12항 1번
        if (next === 'ㄷ') return ResultJ2P.create('ㄴ', 'ㅌ');  // 4장 12항 1번
        if (next === 'ㅈ') return ResultJ2P.create('ㄴ', 'ㅊ');  // 4장 12항 1번
        if (next === 'ㅅ') return ResultJ2P.create('ㄴ', 'ㅆ');  // 4장 12항 2번
        if (next === 'ㄴ') return ResultJ2P.create('ㄴ', 'ㄴ');  // 4장 12항 3번 붙힘
        if (next === 'ㅇ') return ResultJ2P.create('ㄴ', 'ㅎ');  // 4장 14항
        return ResultJ2P.create(curr, next);
    }],

    ['ㄺ', (curr, next, after) => {
        if (next === 'ㅎ') return ResultJ2P.create('ㄹ', 'ㅋ');  // 4장 12항 1번 붙힘1
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄱ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄹ', 'ㄱ');                 // 4장 14항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅇ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create('ㄱ', MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄱ', next);                     // 4장 11항
    }],
    ['ㄻ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㅁ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄹ', 'ㅁ');                 // 4장 14항
        }
        if (next && PRONUNCIATION_6_24_25.includes(next) && !(next === 'ㄱ' && after ==='ㅣ')) return ResultJ2P.create('ㅁ', MAP_DOENSOLI.get(next));    // 6장 24항
        return ResultJ2P.create('ㅁ', next);                     // 4장 11항
    }],

    ['ㄼ', (curr, next, after) => {
        if (next === 'ㅎ') return ResultJ2P.create('ㄹ', 'ㅍ');  // 4장 12항 1번 붙힘1
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄴ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄹ', 'ㅂ');                 // 4장 14항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅁ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create('ㄹ', MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㄹ', next);                     // 4장 10항
    }],
    ['ㄽ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄴ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄹ', 'ㅆ');                 // 4장 14항
        }
        return ResultJ2P.create('ㄹ', next);                     // 4장 10항
    }],
    ['ㄾ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after === 'ㅣ') {
                return ResultJ2P.create('ㄹ', 'ㅊ')              // 5장 17항
            }
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㄴ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄹ', 'ㅌ');                 // 4장 14항
        }
        if (next === 'ㄴ') return ResultJ2P.create('ㄹ', 'ㄹ');  // 5장 20항 붙힘1
        if (next && PRONUNCIATION_6_24_25.includes(next)) return ResultJ2P.create('ㄹ', MAP_DOENSOLI.get(next));    // 6장 25항
        return ResultJ2P.create('ㄹ', next);                     // 4장 10항
    }],
    ['ㄿ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㅂ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄹ', 'ㅍ');                 // 4장 14항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅁ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create('ㅂ', MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㅂ', next);                     // 4장 11항
    }],
    ['ㅀ', (curr, next) => {
        if (next === 'ㄱ') return ResultJ2P.create('ㄹ', 'ㅋ');  // 4장 12항 1번
        if (next === 'ㄷ') return ResultJ2P.create('ㄹ', 'ㅌ');  // 4장 12항 1번
        if (next === 'ㅈ') return ResultJ2P.create('ㄹ', 'ㅊ');  // 4장 12항 1번
        if (next === 'ㅅ') return ResultJ2P.create('ㄹ', 'ㅆ');  // 4장 12항 2번
        if (next === 'ㄴ') return ResultJ2P.create('ㄹ', 'ㄹ');  // 4장 12항 3번 붙힘, 5장 20항 붙힘1
        if (next === 'ㅇ') return ResultJ2P.create('ㄹ', 'ㅎ');  // 4장 14항
        return ResultJ2P.create(curr, next);
    }],
    ['ㅄ', (curr, next, after) => {
        if (next === 'ㅇ') {
            if (after && PRONUNCIATION_4_15.includes(after)) {
                return ResultJ2P.create(null, 'ㅂ');             // 4장 15항 붙임1
            }
            return ResultJ2P.create('ㄹ', 'ㅆ');                 // 4장 14항
        }
        if (next && PRONUNCIATION_5_18.includes(next)) return ResultJ2P.create('ㅁ', next);  // 5장 18항
        if (next && PRONUNCIATION_6_23.includes(next)) return ResultJ2P.create('ㅂ', MAP_DOENSOLI.get(next));    // 6장 23항
        return ResultJ2P.create('ㅂ', next);                     // 4장 10항
    }],
]);

export function pronounce(text: string): {
    source: ResultDivideHangulJamo,
    notation: string[],
    pronunciation: string[],
} {
    for (let ex of Array.from(PRONUNCIATION_EX)) {
        let regex = new RegExp(`${ex[0]}`, "g")
        text = text.replace(regex, ex[1]);
    }

    let source = divideHangulJamoDetail(text);
    let divided = source.divided.split('');
    let element = source.elements;
    let notation = divided.slice();
    let pronunciation = divided.slice();

    for (let i = 0; i < divided.length; i++) {
        let currJamo = divided[i];
        let nextJamo = i + 1 >= divided.length ? null : divided[i + 1];
        let afterJamo = i + 2 >= divided.length ? null : divided[i + 2];
        let currElement = element[i];

        if (['ㅈ', 'ㅉ', 'ㅊ'].includes(currJamo) && nextJamo === 'ㅕ') {                 // 5항 예외1
            pronunciation[i + 1] = 'ㅓ';
        }

        if (currElement === Element.CHO && currJamo !== 'ㅇ' && nextJamo === 'ㅢ') {      // 5항 예외3
            pronunciation[i + 1] = 'ㅣ';
        }

        if (currElement === Element.JON) {
            let conv = CONV_PRONUNCIATION.get(currJamo);
            if (conv) {
                let rj2p = conv(currJamo, nextJamo, afterJamo)
                pronunciation[i] = rj2p.curr == null ? ' ' : rj2p.curr;
                pronunciation[i + 1] = rj2p.next == null ? ' ' : rj2p.next;
            }
        }
    }
    return {
        source: source,
        notation: notation,
        pronunciation: pronunciation
    }
}