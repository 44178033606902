import React, {useState} from "react";
import {Button, Checkbox, FormControlLabel, withStyles, WithStyles} from "@material-ui/core";
import FormControlInput from "../common/custom-components/FormControlInput";
import styles from "./styles";

interface BundleSearchActionProps extends WithStyles<typeof styles> {
}

function BundleSearchAction(props: BundleSearchActionProps) {
    let [text, setText] = useState<string>("");

    let [includeGoogle, setIncludeGoogle] = useState<boolean>(false);
    let [includeNaver, setIncludeNaver] = useState<boolean>(false);
    let [includeDaum, setIncludeDaum] = useState<boolean>(false);

    let [modeKeyword, setModeKeyword] = useState<boolean>(false);
    let [modeImage, setModeImage] = useState<boolean>(false);
    let [modeDictionary, setModeDictionary] = useState<boolean>(false);

    let urls = new Map<string, string>();
    urls.set("keyword:daum", "https://search.daum.net/search?q=");
    urls.set("keyword:naver", "https://search.naver.com/search.naver?query=");
    urls.set("keyword:google", "https://www.google.com/search?q=");

    urls.set("image:daum", "https://search.daum.net/search?w=img&q=");
    urls.set("image:naver", "https://search.naver.com/search.naver?where=image&query=");
    urls.set("image:google", "https://www.google.com/search?tbm=isch&q=");

    urls.set("dictionary:daum", "https://dic.daum.net/search.do?q=");
    urls.set("dictionary:naver", "https://dict.naver.com/search.dict?dicQuery=");
    urls.set("dictionary:google", "https://translate.google.com/?sl=ko&tl=en&text=");

    let launch = () => {
        let bullets = [];
        let modes = [];
        let engines = [];
        if(modeKeyword) {
            modes.push("keyword");
        }
        if(modeImage) {
            modes.push("image");
        }
        if(modeDictionary) {
            modes.push("dictionary");
        }

        if(includeGoogle) {
            engines.push("google");
        }
        if(includeNaver) {
            engines.push("naver");
        }
        if(includeDaum) {
            engines.push("daum");
        }

        for(let m of modes) {
            for(let e of engines) {
                let key = `${m}:${e}`;
                let url = urls.get(key);
                if(url) {
                    bullets.push(url + text);
                }
            }
        }

        for (let bullet of bullets) {
            const w = window.open(bullet, '_blank', 'noopener,noreferrer');
            if (w) w.opener = null;

        }
    }

    return (
        <div className={props.classes.root}>
            <FormControlInput label="검색어" value={text} fullWidth
                              onChange={(e) => {setText(e.target.value)}}
            />
            <FormControlLabel label="Google"
                              control={<Checkbox checked={includeGoogle} color="primary"
                                                 onChange={e => setIncludeGoogle(e.target.checked)}/>}
            />
            <FormControlLabel label="Naver"
                              control={<Checkbox checked={includeNaver} color="primary"
                                                 onChange={e => setIncludeNaver(e.target.checked)}/>}
            />
            <FormControlLabel label="Daum"
                              control={<Checkbox checked={includeDaum} color="primary"
                                                 onChange={e => setIncludeDaum(e.target.checked)}/>}
            />

            <br/>

            <FormControlLabel label="Keyword"
                              control={<Checkbox checked={modeKeyword} color="primary"
                                                 onChange={e => setModeKeyword(e.target.checked)}/>}
            />
            <FormControlLabel label="Image"
                              control={<Checkbox checked={modeImage} color="primary"
                                                 onChange={e => setModeImage(e.target.checked)}/>}
            />
            <FormControlLabel label="Dictionary"
                              control={<Checkbox checked={modeDictionary} color="primary"
                                                 onChange={e => setModeDictionary(e.target.checked)}/>}
            />
            <br/>
            <hr/>
            <Button variant="contained" color="primary" component="span" onClick={() => launch()}>한번에 검색!</Button>

        </div>
    )
}

export default withStyles(styles)(BundleSearchAction);