import {Theme} from '@material-ui/core';

const styles = (theme: Theme) => {
    return {
        root: {
            "& > *": {
                margin: theme.spacing(1, 0, 1, 0),
            },
            // "& .MuiInputBase-root": {
            //     margin: theme.spacing(1),
            //     width: "20ch",
            //     height: "30px",
            //     backgroundColor: "green"
            // }
        },
    }
}

export default styles;
