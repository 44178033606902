import React, {useCallback, useState} from "react";
import {WithStyles, withStyles} from "@material-ui/core";
import FormControlInput from "../common/custom-components/FormControlInput";
import styles from "./styles";
import {syntaxHighlight} from "./JsonPrettyPrintingFunction";

interface JsonPrettyPrintingActionProps extends WithStyles<typeof styles> {
}

function JsonPrettyPrintingAction(props: JsonPrettyPrintingActionProps) {
    const [result, setResult] = useState({
        source: '',
        result: '',
        error: '',
    })

    const handleSourceChange = useCallback(e => {
        const { value } = e.target;
        let jsonStr = "";
        let errorStr = "";
        try {
            const jsonObj = JSON.parse(value)
            jsonStr = JSON.stringify(jsonObj, null, 2);
            const jsonElement = document.getElementById("json");
            if (jsonElement) {
                jsonElement.innerHTML = syntaxHighlight(jsonStr);
            }
        } catch (e) {
            errorStr = e.message;
        }
        setResult({ source: value, result: jsonStr, error: errorStr })
    }, []);

    const { classes } = props;

    return (
        <form className={classes.root}>
            <FormControlInput label="JSON" value={result.source} multiline fullWidth autoFocus
                              helperText={result.error}
                              onChange={handleSourceChange} />
            <FormControlInput label="Result" value={result.result} multiline fullWidth readOnly />
            <pre id="json" />
        </form>
    );
}

export default withStyles(styles)(JsonPrettyPrintingAction);
