// TODO seconds, milliseconds, microseconds, nanoseconds에 대해 모두 대응해야 함.
import {isValidDate, toStandardFormatDate} from "../ticks-to-date/date";

export interface IResult {
    value: string,
    utc: string,
    local: string,
    type?: string,
}

const MILLISECONDS = 1000;

export function timestampToDatetime(value: string): IResult {
    const numberValue = parseInt(value);
    let isSeconds = value.length < 12;
    let date = new Date(isSeconds ? numberValue * MILLISECONDS : numberValue);
    if (isValidDate(date)) {
        const local = toStandardFormatDate(date);
        date.setMinutes(date.getMinutes() + new Date().getTimezoneOffset())
        const utc = toStandardFormatDate(date)
        return { value: value, local, utc, type: isSeconds ? 'seconds' : 'milliseconds' };
    } else {
        return { value: value, local: '', utc: '' };
    }
}

export function datetimeToTimestamp(value:string): IResult {
    const date = new Date(value);
    const local = (date.getTime() / MILLISECONDS).toString();
    date.setMinutes(date.getMinutes() + new Date().getTimezoneOffset());
    const utc = (date.getTime() / MILLISECONDS).toString();
    return { value: value, local, utc};
}
